<template>
  <div class="body">
    <Header @clickBack="backHome">审核计划</Header>
    <div class="contain">
      <div class="_SelectHeader">
        <div class="_Select" v-if="!showTable2">
          <span>年份：</span>
          <el-date-picker
            v-model="currentYear"
            format="yyyy"
            value-format="yyyy"
            type="year"
            placeholder="选择年份"
          >
          </el-date-picker>
        </div>
        <!-- <div class="_Select">
          <span>审核状态：</span>
          <el-select v-model="auditType" placeholder="请选择">
            <el-option
              v-for="item in ['已审核', '未审核']"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div> -->
        <!-- <div class="_Select" v-if="showTable2">
          <span>所属部门：</span>
          <el-select v-model="selectDept" clearable placeholder="请选择">
            <el-option
              v-for="item in deptList"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div> -->
        <el-button
          v-if="!showTable2"
          type="success"
          icon="el-icon-search"
          class="find"
          @click="getDataList"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-document-add"
          type="primary"
          @click="addPlan"
          v-if="!showTable2"
          >新增计划</el-button
        >
        <el-button v-if="showTable2" type="primary" @click="openCalendar">
          <i
            class="el-icon-circle-plus-outline"
            style="margin-right: 3px; font-weight: bold"
          ></i>
          <i
            class="el-icon-edit"
            style="margin-right: 3px; font-weight: bold"
          ></i>
          <i class="el-icon-delete" style="font-weight: bold"></i>
          小组长</el-button
        >
        <img
          v-if="!showTable2"
          src="../../assets/images/Examine_img/del.png"
          @click="deleteItem"
        />
        <img
          v-if="!showTable2"
          src="../../assets/images/Examine_img/edit.png"
          @click="editItem"
        />
        <el-button style="margin-left: 10" @click="exportExcel">导出</el-button>
        <el-button
          v-if="showTable2"
          @click="showTable2 = false"
          icon="el-icon-back"
          >返回</el-button
        >
      </div>
      <div class="table">
        <el-table
          v-if="!showTable2"
          :data="tableData.slice(start, end)"
          style="width: 100%"
          :height="elementHeight"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          id="table"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick"
          @row-dblclick="rowDblclick"
        >
          <el-table-column prop="compName" label="公司" width="180">
          </el-table-column>
          <el-table-column prop="planBeginDate" label="开始时间">
            <template slot-scope="scope">
              <span>{{
                new Date(scope.row.planBeginDate).toLocaleDateString()
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="planEndDate" label="结束时间">
            <template slot-scope="scope">
              <span>{{
                new Date(scope.row.planEndDate).toLocaleDateString()
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="checkName" label="负责组长"> </el-table-column>
          <el-table-column prop="planType" label="审核类型" width="180">
          </el-table-column>
          <!-- <el-table-column prop="address" label="批准状态">
            <template slot-scope="scope">
              <el-button
                type="primary"
                v-if="scope.row.approvaStatus == 0"
                @click="approvePlan(scope.row)"
                >批准</el-button
              >
              <span v-else>已批准</span>
            </template>
          </el-table-column> -->
        </el-table>
        <el-table
          :data="tableData2.slice(start, end)"
          v-if="showTable2"
          style="width: 100%"
          :height="elementHeight"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          id="table2"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick2"
          :span-method="objectSpanMethod2"
          border
        >
          <el-table-column prop="" label="计划编号" width="180">
            <template>
              <span>{{ dataItem.planNo }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="compName" label="公司" width="180">
          </el-table-column>
          <el-table-column prop="deptName" label="部门" width="180">
          </el-table-column>
          <el-table-column prop="auditDate" label="审核时间">
            <template slot-scope="scope">
              <span>{{
                new Date(scope.row.auditDate).toLocaleDateString()
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="saveDate" label="保存时间">
            <template slot-scope="scope">
              <span>{{
                new Date(scope.row.saveDate).toLocaleDateString()
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="checkName" label="组长"> </el-table-column>
          <el-table-column prop="auditUserName" label="小组长">
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          v-if="!showTable2"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page.sync="currentPage1"
          :page-sizes="[10, 20, 50]"
          :page-size="curSize1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
        >
        </el-pagination>
        <el-pagination
          v-if="showTable2"
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page.sync="currentPage2"
          :page-sizes="[10, 20, 50]"
          :page-size="curSize2"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData2.length"
        >
        </el-pagination>
      </div>
    </div>
    <Dialog
      :title="isAdd ? '新增计划' : '修改计划'"
      @handleClose="handleClose"
      width="24%"
      top="20vh"
      :showTowText="true"
      @handleReqData="handleReqData"
      :showDialog="showDialog"
      class="dialog"
    >
      <el-form label-width="90px">
        <el-form-item label="计划类型：">
          <el-select v-model="planType" placeholder="请选择">
            <el-option
              v-for="item in [
                '股份公司年度审核',
                '子公司年度审核',
                '股份公司季度审核',
                '子公司季度审核',
                '自查自纠',
                '7s检查',
              ]"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="季度"
          v-if="planType == '股份公司季度审核' || planType == '子公司季度审核'"
        >
          <!-- ４个季度，分别传值１，２，３，４ -->
          <el-select v-model="quarter" placeholder="请选择">
            <el-option
              v-for="(item, index) in [
                { lable: '第一季', value: 1 },
                { lable: '第二季', value: 2 },
                { lable: '第三季', value: 3 },
                { lable: '第四季', value: 4 },
              ]"
              :key="index"
              :label="item.lable"
              :value="item.value + ''"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- //类型改为四个股份公司，股份公司季度要选季度。公司绑定登录人的公司，计划编号根据后端获取并隐藏，组长绑定所有人并能边输边选 -->
        <!-- <el-form-item label="所属公司：">
          <el-select v-model="company" placeholder="请选择" disabled>
            <el-option
              v-for="item in cpmpyList"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->

        <!-- <el-form-item label="计划编号：">
          <el-select
            v-model="planNo"
            clearable
            filterable
            :filter-method="changFilterable"
            @blur="blurFilterable"
            :placeholder="
              new Date().getFullYear() +
              '-' +
              (new Date().getMonth() + 1) +
              '+您输入的编号'
            "
          >
            <el-option
              v-for="item in planNoList"
              :key="item.planNo"
              :label="item.planNo"
              :value="item.planNo"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="年份：">
          <el-date-picker
            v-model="year"
            format="yyyy"
            value-format="yyyy"
            type="year"
            placeholder="选择年"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开始时间：">
          <el-date-picker
            v-model="planBeginDate"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：">
          <el-date-picker
            v-model="planEndDate"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="负责组长：">
          <el-select
            v-model="checkNo"
            filterable
            clearable
            placeholder="请选择"
            v-loading="isShowLoading"
          >
            <el-option
              v-for="item in personList"
              :key="item.userNo"
              :label="item.userName"
              :value="item.userNo"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Dialog>
    <Dialog
      title="添加小组长"
      @handleClose="handleClose2"
      width="95%"
      top="5vh"
      :showTowText="true"
      @handleReqData="handleReqData2"
      :showDialog="showDialog2"
      class="dialog2"
    >
      <el-calendar :range="['2019-03-04', '2020-03-24']">
        <template slot="dateCell" slot-scope="{ data }">
          <div
            v-loading="cloneLoading && dateIndex == data.day"
            @click.stop="clickCalendarItem(data.day)"
            class="calendar"
            :class="[cloneValue ? 'calendarClone' : null]"
          >
            <span class="span">
              {{ data.day.split('-').slice(2).join('-') }}</span
            >
            <div class="data-list">
              <div
                class="dataItem"
                v-for="item in dataList"
                :key="item.auditDate"
                style="font-size: 20px; color: #41a8db"
              >
                <span v-if="data.day == item.auditDate">{{
                  item.auditUserName
                }}</span>
              </div>
            </div>
            <div class="icon" v-if="dateIndex == data.day">
              <i
                class="el-icon-circle-plus-outline"
                @click.stop="addGroupLeader"
              ></i>
              <i
                v-if="groupLeaderItem.auditUserName"
                class="el-icon-edit"
                @click.stop="modifyGroupLeader"
              ></i>
              <i
                v-if="groupLeaderItem.auditUserName"
                class="el-icon-document-copy"
                @click.stop="clone(data.day)"
              ></i>
              <i
                v-if="groupLeaderItem.auditUserName"
                class="el-icon-delete"
                @click.stop="deleteGroupLeader(data.day)"
              ></i>
            </div>
            <i
              class="el-icon-document"
              v-if="dateIndex != data.day && cloneValue"
              @click.stop="fulfilClone(data.day)"
            ></i>
          </div>
        </template>
      </el-calendar>
    </Dialog>
    <Dialog
      title="选择小组长"
      @handleClose="handleClose3"
      width="23%"
      top="10vh"
      :showTowText="true"
      @handleReqData="handleChecked"
      :showDialog="showDialog3"
      class="dialog2"
    >
      <div style="margin-left: 40px; margin-top: 40px">
        <span>所属公司：</span>
        <el-select
          v-model="company2"
          @change="changCompny"
          placeholder="请选择"
        >
          <el-option
            v-for="item in cpmpyList"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-left: 40px; margin-top: 20px">
        <span>所属部门：</span>
        <el-select v-model="dept" placeholder="请选择">
          <el-option
            v-for="item in deptList"
            :key="item"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-left: 40px; margin-top: 20px; margin-bottom: 20px">
        <span>负责组长：</span>
        <el-select
          v-model="persons"
          multiple
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in personList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </Dialog>
    <Dialog
      :title="(!isAddGroupLeader ? '修改' : '删除') + '小组长'"
      @handleClose="handleClose4"
      width="25%"
      top="20vh"
      :showTowText="true"
      @handleReqData="handleClose4"
      :showDialog="showDialog4"
      class="dialog2"
    >
      <div
        class="group-name"
        v-for="(item, index) in groupLeaderList"
        :key="index"
      >
        {{ index + 1 + '、' }}<span>{{ item.auditUserName }}</span>
        <i
          v-if="!isAddGroupLeader"
          class="el-icon-edit"
          style="font-size: 16px; color: #e1763c"
          @click="showGroupLeader(item)"
        ></i>
        <i
          style="font-size: 16px; color: red"
          class="el-icon-delete"
          v-if="isAddGroupLeader"
          @click="reqDeleteGroup(item, index)"
        ></i>
      </div>
      <div style="margin-bottom: 20px"></div>
    </Dialog>
  </div>
</template>

<script>
import Header from '../../components/architecture/Header.vue'
import Dialog from '../../components/dialog/Dialog.vue'
import { get, post, put, deleteMethod } from '../../api/http'
import { exportExcel } from '../../modules/ExportExcel'
import { objectSpanMethod2 } from '../../modules/TableConflate'
export default {
  components: {
    Header,
    Dialog,
  },
  // 定义属性
  data() {
    return {
      elementHeight: 0,
      currentYear: new Date().getFullYear() + '',
      yearsList: [], //年份控制在前后五年
      tableData: [],
      tableData2: [],
      currentPage1: 1,
      start1: 0,
      end1: 10,
      curSize1: 10,
      currentPage2: 1,
      start2: 0,
      end2: 10,
      curSize2: 10,
      tableRowIndex: null,
      dataItem: {},
      showTable2: false,
      selectDept: null,
      //计划弹窗
      planType: '',
      showDialog: false,
      isAdd: false,
      company: null,
      cpmpyList: [],
      year: '',
      planBeginDate: '',
      planEndDate: '',
      planNo: '',
      planNoList: [],
      checkNo: '',
      quarter: '',
      personList: [],
      isShowLoading: false,
      // 日历弹窗
      showDialog2: false,
      dataList: [],
      dateIndex: '',
      nameValue: '',
      cloneValue: false,
      // 选择组长=小计划
      showDialog3: false,
      personListSele: [],
      userName: '',
      persons: [],
      loading: false,
      historySearchlist: [],
      isAddGroupLeader: false,
      groupLeaderItem: {},
      dept: null,
      deptList: [],
      company2: null,
      showDialog4: false,
      groupLeaderList: [],
      cloneDateIndex: '',
      cloneNumber: 0,
      cloneLength: 0,
    }
  },
  mounted() {
    this.getElementHeight()
    this.getDataList()
    this.cpmpyList = this.$store.state.companyUserList
    this.company = JSON.parse(sessionStorage.getItem('userInfo')).upCompanyId
    this.getPerson()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 52 + 20) //分页加底部距离
      })
    },
    exportExcel() {
      return exportExcel(
        this.showTable2 ? '#table2' : '#table',
        '审核计划.xlsx'
      )
    },
    getPerson() {
      this.isShowLoading = true
      get('/api/User')
        .then((resp) => {
          this.isShowLoading = false
          if (resp.code == 200) {
            this.personList = resp.data
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.isShowLoading = false
          this.$message.error('系统异常,请稍后再试')
        })
    },
    async getDataList() {
      this.currentPage1 = 1
      this.tableRowIndex = null
      this.tableData = []
      await get('/api/FileAuditPlan/GetAll?Year=' + this.currentYear).then(
        (resp) => {
          if (resp.code == 200) {
            this.tableData = resp.data
          }
        }
      )
    },

    rowClick(row) {
      this.tableRowIndex = row.index
      this.dataItem = row
    },
    rowDblclick() {
      this.showTable2 = true
      this.getSmallPlan()
    },
    rowClick2() {},
    objectSpanMethod2({ rowIndex, columnIndex }) {
      return objectSpanMethod2(rowIndex, columnIndex, this.tableData2)
    },
    getSmallPlan() {
      get('/api/FileAuditPlan/GetByPlanId?PlanId=' + this.dataItem.id).then(
        (res) => {
          if (res.code == 200) {
            this.tableData2 = res.data
          }
        }
      )
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
      if (rowIndex % 2 == 0) {
        return ''
      } else {
        return 'warning-row'
      }
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex && !this.showTable2) {
        return {
          border: '5px solid',
          'background-color': '#E2E8FA !important',
        }
      }
    },
    // 添加/修改/删除/审核计划列表
    addPlan() {
      this.isAdd = true
      this.showDialog = true
      this.year = ''
      this.planBeginDate = ''
      this.planEndDate = ''
      this.checkNo = ''
      this.planType = ''
      this.quarter = ''
    },
    editItem() {
      if (JSON.stringify(this.dataItem) == '{}') {
        this.$message({
          type: 'warning',
          message: '未选中列表',
        })
        return
      }
      this.showDialog = true
      this.isAdd = false
      this.planType = this.dataItem.planType
      this.quarter = this.dataItem.quarter
      this.company = this.dataItem.compId
      this.planNo = this.dataItem.planNo
      this.year = this.dataItem.planYear + ''
      this.planBeginDate = this.dataItem.planBeginDate
      this.planEndDate = this.dataItem.planEndDate
      this.checkNo = this.dataItem.checkUno
    },
    handleReqData() {
      var company = this.cpmpyList.filter((i) => {
        return i.id == this.company
      })
      var person = this.personList.filter((i) => {
        return i.userNo == this.checkNo
      })
      var user = JSON.parse(sessionStorage.getItem('userInfo'))
      var data = {
        id: this.isAdd ? 0 : this.dataItem.id,
        compId: this.company,
        compName: company[0].companyName,
        planNo: this.isAdd ? '' : this.dataItem.planNo,
        planYear: this.year,
        planBeginDate: this.planBeginDate,
        planEndDate: this.planEndDate,
        approvalUno: '',
        approvalName: '',
        approvalDate: '2022-12-09T07:33:17.860Z',
        approvaStatus: 0,
        planType: this.planType,
        quarter: this.quarter + '',
        remark: '',
        saveUno: user.userNo,
        saveName: user.userName,
        saveDate: new Date().toISOString(),
        checkUno: person[0].userNo,
        checkName: person[0].userName,
        checkDate: '2022-12-09T07:33:17.860Z',
        chargeUno: user.userNo,
        chargeName: user.userName,
        isDelete: 0,
      }
      var methods = this.isAdd ? post : put
      methods('/api/FileAuditPlan', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.getDataList()
            this.showDialog = false
          } else {
            this.$message({
              type: 'error',
              message: this.isAdd ? '新增失败' : '修改失败',
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: this.isAdd ? '新增失败' : '修改失败',
          })
        })
    },
    // changFilterable(e) {
    //   this.planNo = e
    // },
    // blurFilterable() {
    //   this.planNo =
    //     new Date().getFullYear() +
    //     '-' +
    //     (new Date().getMonth() + 1) +
    //     this.planNo
    // },
    handleClose() {
      this.showDialog = false
    },
    deleteItem() {
      if (JSON.stringify(this.dataItem) == '{}') {
        this.$message({
          type: 'warning',
          message: '未选中列表',
        })
        return
      }
      this.$confirm('您确认要删除这个计划？')
        .then(() => {
          deleteMethod('/api/FileAuditPlan?Id=' + this.dataItem.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.tableData.splice(this.tableRowIndex, 1)
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    approvePlan(e) {
      var user = JSON.parse(sessionStorage.getItem('userInfo'))
      put(
        '/api/FileAuditPlan/Approve?PlanId=' +
          e.id +
          '&ApprovalUno=' +
          user.userNo +
          '&ApprovalName=' +
          user.userName
      )
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '批准成功',
            })
            e.approvaStatus = '已批准'
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('批准失败')
        })
    },
    // 分页
    handleSizeChange1(e) {
      this.curSize1 = e
      this.start1 = (this.currentPage1 - 1) * this.curSize1
      this.end1 = this.start1 + this.curSize1
    },
    handleCurrentChange1(e) {
      this.tableRowInde = null
      this.start1 = (e - 1) * this.curSize1
      this.end1 = this.start1 + this.curSize1
    },
    handleSizeChange2(e) {
      this.curSize2 = e
      this.start2 = (this.currentPage2 - 1) * this.curSize2
      this.end2 = this.start2 + this.curSize2
    },
    handleCurrentChange2(e) {
      this.start2 = (e - 1) * this.curSize2
      this.end2 = this.start2 + this.curSize2
    },
    clearPresonInp() {
      this.isAddGroupLeader = true
      this.userName = ''
      this.deptList = []
      this.dept = null
      this.persons = []
    },
    changCompny(e) {
      this.getDept(e)
    },
    getDept(e) {
      get('/api/Department/GetNoTree?CompId=' + e).then((res) => {
        if (res.code == 200) {
          this.deptList = res.data
        }
      })
    },
    // 对日历的操作
    openCalendar() {
      this.showDialog2 = true
      this.dateIndex = null
      this.getGroupLeader()
    },
    handleReqData2() {
      this.showDialog2 = false
    },
    handleClose2() {
      this.showDialog2 = false
    },
    clickCalendarItem(day) {
      this.dateIndex = day
      this.cloneValue = false
      this.groupLeaderItem = {}
      this.dataList.map((i) => {
        if (this.dateIndex == i.auditDate) {
          this.groupLeaderItem = i
          return
        }
      })
    },
    clone(day) {
      this.cloneDateIndex = day
      this.cloneValue = true
      this.$message({
        type: '',
        message: '复制成功',
      })
    },
    fulfilClone(day) {
      this.cloneLoading = true
      this.isAddGroupLeader = true
      this.dateIndex = day
      this.cloneNumber = 0
      this.cloneLength = 0
      this.dataList.map((i) => {
        if (this.cloneDateIndex == i.auditDate) {
          this.cloneNumber += 1
          setTimeout(() => {
            this.showGroupLeader(i)
          }, 2000)
        }
      })
    },
    // 选择小组长
    getGroupLeader() {
      get('/api/AuditPeople/GetByPlanId?PlanId=' + this.dataItem.id).then(
        (resp) => {
          this.dataList = resp.data
        }
      )
    },
    handleClose3() {
      this.showDialog3 = false
      this.clearPresonInp()
    },
    addGroupLeader() {
      this.showDialog3 = true
      this.isAddGroupLeader = true
      this.clearPresonInp()
    },
    modifyGroupLeader() {
      this.isAddGroupLeader = false
      this.persons = []
      var length = 0
      this.groupLeaderList = []
      this.dataList.filter((i) => {
        if (i.auditDate == this.dateIndex) {
          length++
          this.groupLeaderList.push(i)
        }
      })
      if (length > 1) {
        this.showDialog4 = true
      } else {
        this.showGroupLeader(this.groupLeaderList[0])
      }
    },
    showGroupLeader(i) {
      if (!this.isAddGroupLeader) {
        //修改
        this.showDialog3 = true
        this.showDialog4 = false
        i.auditUserId.split(',').map((i) => {
          this.persons.push(i * 1)
        })
      }
      this.company2 = i.compId
      this.dept = i.deptId
      this.getDept(i.compId)
      this.groupLeaderItem = i
      if (this.isAddGroupLeader) {
        // 克隆
        this.reqGroupLeader(
          this.groupLeaderItem.auditUserId,
          this.groupLeaderItem.auditUserName
        )
      }
    },
    handleClose4() {
      this.showDialog4 = false
    },
    handleChecked() {
      var checkedPersonsId = ''
      var checkedPersonsName = ''
      this.personList.map((i) => {
        this.persons.map((e) => {
          if (i.id == e) {
            checkedPersonsId =
              checkedPersonsId == '' ? i.id : checkedPersonsId + ',' + i.id
            checkedPersonsName =
              checkedPersonsName == ''
                ? i.userName
                : checkedPersonsName + ',' + i.userName
          }
        })
      })
      this.reqGroupLeader(checkedPersonsId, checkedPersonsName)
    },
    reqGroupLeader(auditUserId, auditUserName) {
      var user = JSON.parse(sessionStorage.getItem('userInfo'))
      var deptName = ''
      this.deptList.forEach((i) => {
        if (i.id == this.dept) deptName = i.deptmentName
      })
      var company2Name = ''
      this.cpmpyList.forEach((i) => {
        if (i.id == this.company2) company2Name = i.companyName
      })
      var data = {
        id: this.isAddGroupLeader ? 0 : this.groupLeaderItem.id,
        auditPlanId: this.tableData[this.tableRowIndex].id,
        auditDate: this.dateIndex,
        auditUserId: auditUserId + '',
        auditUserName: auditUserName,
        saveUserId: user.id + '',
        saveUserNo: user.userNo,
        saveUserName: user.userName,
        saveDate: new Date().toISOString(),
        deptId: this.dept,
        deptName: deptName,
        compId: this.company2 * 1,
        compName: company2Name,
      }
      var methods = this.isAddGroupLeader ? post : put
      methods('/api/AuditPeople', data)
        .then((resp) => {
          if (resp.code == 200) {
            if (!this.cloneDateIndex) {
              this.showDialog3 = false
              this.handleSuccess()
            } else {
              this.cloneLength += 1
              if (this.cloneNumber == this.cloneLength) {
                this.cloneLoading = false
                this.cloneValue = false
                this.cloneDateIndex = ''
                this.handleSuccess()
              }
            }
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('系统异常')
        })
    },
    handleSuccess() {
      this.$message({
        type: 'success',
        message: '成功',
      })
      this.dateIndex = null
      this.groupLeaderItem = {}
      this.getSmallPlan()
      this.getGroupLeader()
    },
    deleteGroupLeader() {
      this.isAddGroupLeader = true
      var length = 0
      this.groupLeaderList = []
      this.dataList.filter((i) => {
        if (i.auditDate == this.dateIndex) {
          length++
          this.groupLeaderList.push(i)
        }
      })
      if (length > 1) {
        this.showDialog4 = true
      } else {
        this.reqDeleteGroup(this.groupLeaderList[0], 0)
      }
    },
    reqDeleteGroup(i, index) {
      this.$confirm('此操作将永久删除小组长：' + i.auditUserName)
        .then(() => {
          deleteMethod('/api/AuditPeople?Id=' + i.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.clearPresonInp()
                this.groupLeaderItem = {}
                this.getGroupLeader()
                this.getSmallPlan()
                this.groupLeaderList.splice(index, 1)
                if (this.groupLeaderList.length == 0) {
                  this.showDialog4 = false
                }
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    backHome() {
      this.$router.push(
        './home?leftIndex=3&title=控制中心&fromPagePath=ControlPage'
      )
    },
  },
}
</script>

<style lang='less' scoped>
.body {
  background-color: #e7e9ec;
  height: 100vh;
}
._SelectHeader {
  margin-bottom: 1px;
  img {
    width: 30px;
    height: 30p Headerx;
    margin-left: 10px;
  }
}
._SelectHeader,
.pagination,
.table {
  background-color: white;
}
.el-pagination {
  position: relative !important;
  padding-right: 20px;
}
.pagination {
  text-align: end;
  width: 100%;
  padding: 10px 0;
}
.contain {
  padding: 0 10px;
  margin-top: 5px;
}
.table {
  padding: 10px 10px 0 10px;
}
.time {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .demonstration {
    width: 80px;
  }
}
/deep/ .el-dialog__header {
  background-color: white !important;
  border-bottom: 1px solid rgba(192, 192, 192, 0.419);
  margin: 10px;
}
/deep/ .el-dialog__headerbtn .el-dialog__close,
/deep/ .el-dialog__title {
  color: rgb(0, 0, 0) !important;
}
.selectInput {
  width: 100%;
  span {
    width: 80px !important;
    text-align: start;
  }
  > div {
    width: 220px;
  }

  ::-webkit-input-placeholder {
    color: #c0c0c0fc;
  }
  :-moz-placeholder {
    color: #c0c0c0fc;
  }
  ::moz-placeholder {
    color: #c0c0c0fc;
  }
  :-ms-input-placeholder {
    color: #c0c0c0fc;
  }
}
/deep/ .dialog2 .el-dialog__body {
  padding: 0 !important;
}
/deep/ .dialog2 .el-calendar-table .el-calendar-day {
  height: 60px !important;
  padding: 0 !important;
}
/deep/ .dialog2 .span {
  width: 15px;
  white-space: nowrap;
}
/deep/ .dialog2 .span {
  padding-left: 10px;
  padding-top: 10px;
  display: inline-block;
}

.el-select {
  // width: 200px !important;
  /deep/.el-select__tags {
    overflow: hidden !important;
    flex-wrap: nowrap !important;
  }
}
.calendar {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 60px;
  .data-list {
    overflow-y: auto;
    flex: 1;
    padding: 5px 10px;
    .dataItem {
      line-height: 15px;
      margin: 5px 0;
    }
  }
  ::-webkit-scrollbar {
    margin-top: 3px;
    width: 2px !important;
  } //设置滚动条显示隐藏
  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
  }
  /* 滚动槽 */
  ::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
    background-color: rgb(218, 216, 216);
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgb(92, 90, 90);
    /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
  }
}
.el-icon-document {
  display: none;
  font-size: 24px;
  position: absolute;
  left: 45%;
  top: 30%;
  color: silver;
}
.icon {
  width: 100%;
  position: absolute;
  height: 60px;
  line-height: 65px;
  font-size: 22px;
  text-align: center;
  background-color: #00000014;
  backdrop-filter: blur(1.5px);
  .el-icon-document-copy,
  .el-icon-edit,
  .el-icon-delete {
    padding: 0 6px;
  }
  .el-icon-circle-plus-outline {
    color: #f95353;
  }
  .el-icon-edit {
    color: #e96c29;
  }
  .el-icon-delete {
    color: red;
  }
  .el-icon-document-copy {
    color: #757474;
  }
}

.current :hover .el-icon-document {
  display: block;
}
.select-name {
  display: flex;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 50px;
  align-items: center;
  .el-select {
    width: 300px !important;
    input {
      border: none;
      outline: none !important;
    }
  }
  .input-name {
    width: 150px !important;
    border: 1px solid #c0c0c0d5;
    height: 28px !important;
    line-height: 28px !important;
    font-size: 13px !important;
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0fc;
  }
  :-moz-placeholder {
    color: #c0c0c0fc;
  }
  ::moz-placeholder {
    color: #c0c0c0fc;
  }
  :-ms-input-placeholder {
    color: #c0c0c0fc;
  }
}
/deep/.dialog .el-input {
  width: 200px !important;
}
.el-form-item {
  margin-bottom: 0 !important;
}
.group-name {
  padding: 8px 30px;
  font-size: 14px;
  > span {
    font-size: 14px;
    color: #00a2ff;
    margin-right: 8px;
  }
}
</style>