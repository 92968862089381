<template>
  <div class="header">
    <div>
      <span class="title"><slot></slot></span>
      <el-button type="primary" class="btn" @click="clickBack">
        返回首页
      </el-button>
    </div>
    <i class="el-icon-close" @click="clickBack"></i>
  </div>
</template>

<script>
export default {
  mounted() {},
  methods: {
    clickBack() {
      this.$emit('clickBack', null)
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 0 20px;
  height: 60px;
}
.title {
  color: rgb(135, 134, 134);
  font-size: 16px;
  font-weight: bold;
}
</style>